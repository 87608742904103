.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 120;
}

.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 120;
    padding: .5rem;
}

.header__image {
    width: 6rem;
}

.header__image > img {
    width: 100%;
}

.header__list {
    display: flex;
    gap: 3rem;
    margin-top: 1rem;
    font-family: kalameh;
}

.header__list > a {
    color: #212121;
    transition: .4s;
}

.header__list > a:hover {
    color: #8661C6;
}

.header__bars {
    display: none;
}

.header__bars > svg {
    font-size: 1.5rem;
    cursor: pointer;
    color: #6F6F6F;
}

.header__icons {
    display: flex;
    gap: 1rem;
    position: relative;
}

.header__icons span {
    margin: 0px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    background-color: rgb(239, 57, 78);
    height: 20px;
    width: 20px;
    line-height: 17px;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    top: -5px;
    right: 0;
}

.header__icons > a {
    background-color: #E8EAF6;
    padding: .6rem .8rem;
    border-radius: 1rem;
}

.header__icons > a >svg {
    color: #8661C6;
    font-size: 1.5rem;
    cursor: pointer;
}

.sidemenu {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    height: 100%;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.data__sidemenu {
    display: flex;
    flex-direction: column;
}

.times {
    text-align: left;
}

.times > svg {
    font-size: 1.2rem;
    color: #757575;
}

.data__sidemenu > hr {
    margin-top: 5px !important;
    margin-bottom: 1rem !important;
    color: #757575;
}

.data__sidemenu > ul {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-family: kalameh;
}

.data__sidemenu > ul > li > svg {
    font-size: 1.5rem;
    margin-right: 5px;
}

.data__sidemenu > ul > li > a {
    color: #212121;
    cursor: pointer;
    margin-right: .5rem;
}

@media screen and (max-width : 900px) {

    .header__container {
        justify-content: space-between;
    }

    .header__image {
        display: none;
    }

    .header__list {
        display: none;
    }

    .header__bars {
        display: flex;
    }
}