.news__home {
  font-family: kalameh;
}

.top__news__home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.top__news__home > h3 {
  color: #212121;
}

.top__news__home > a {
  color: #7e57c2;
}

.courses__news__home {
  display: grid;
  grid-template-columns: repeat(auto-fit , minmax(250px , 1fr));
  gap: 3rem 2rem;
}

.course__container {
  width: 100%;
  background-color: #fff;
  box-shadow: rgb(94 129 209 / 16%) 0px 8px 24px;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  padding: 0 10px;
}

.course__container > img {
  width: 5rem;
  height: 5rem;
  padding: 0.5rem;
  margin: 1rem;
}

.course__container > h5 {
  color: #212121;
  font-size: 1rem;
  margin-top: 1rem;
}

.time__course {
  display: flex;
  margin-top: 3rem;
  color: #7e57c2;
}

.time__course > svg {
  margin-top: 3px;
  margin-left: 5px;
}

.number__price__course {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.number__course {
  color: #5e81d1;
  background-color: #e8eaf6;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  font-size: 0.9rem;
}

.price__course {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.off__price__course {
  background-color: #ef394e;
  color: #fff;
  width: fit-content;
  padding: 0.4rem;
  border-radius: 10px;
  font-size: 0.8rem;
  box-shadow: #ef394e 0px 2px 10px;
}

.last__price__course {
  color: #757575;
  text-decoration: line-through !important;
  font-size: .8rem;
}

.new__price__course {
  color: #008031;
  font-size: .8rem;
}

.buttons__course {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.buttons__course > button {
  background-color: #7e57c2;
  color: #fff;
  padding: .4rem 1.4rem;
  border-radius: 10px;
  border: none;
  outline: none;
}

.buttons__course > a {
  color: #7e57c2;
}

.buttons__course > a:hover {
  color: #7e57c2;
}

.hide {
  display: none;
}

@media screen and (max-width : 1100px) {
  .news__home {
    margin-top: 2rem;
  }
}

@media screen and (max-width : 900px) {
  .news__home {
    margin-top: 4rem;
  }
}

@media screen and (max-width : 600px) {
  .news__home {
    margin-top: 6rem;
  }
}