@font-face {
  font-family: "kalameh";
  src: url("../public/fonts/Kalameh-Bold.ttf");
}

@font-face {
  font-family: "sans";
  src: url("../public/fonts/Sans\ a4fran3.ttf");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  list-style-type: none !important;
}

html {
  direction: rtl;
}

body {
  background-color: #fcfcfc;
}

.width-lg {
  width: 75%;
  margin: auto;
}

section {
  padding-top: 4rem !important;
}

p {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 1200px) {
  .width-lg {
    width: 85%;
  }
}

@media screen and (max-width: 900px) {
  .width-lg {
    width: 95%;
  }
}