.cart__shop__container {
  margin-top: 6rem;
  font-family: kalameh;
  display: flex;
  justify-content: space-between;
}

.cart__shop__container__courses {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
}

.course__data {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: rgb(94 129 209 / 16%) 0px 8px 24px;
  width: 95%;
  margin-top: 30px;
  border-radius: 10px;
  padding: 25px 15px;
}

.course__data__right {
  display: flex;
  gap: 2rem;
}

.course__data__right__img {
  width: 4rem;
  height: 4rem;
}

.course__data img {
  width: 100%;
}

.course__data__right__data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.course__data__right__data h5 {
  color: #212121;
}

.course__data__right__data p {
  color: #656565;
  font-family: sans;
}

.course__data__left {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px 0px 0px 16px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: YekanBakh, IRANSansWeb, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(211, 47, 47, 0.5);
  color: rgb(211, 47, 47);
  padding: 0px;
  border-radius: 7px;
}

.course__data__left:hover {
  background-color: rgba(211, 47, 47, 0.1);
}

.course__data__left svg {
  user-select: none;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  width: 20px;
}

.cart__shop__container__total {
  width: 22rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.course__total {
  box-shadow: rgb(94 129 209 / 16%) 0px 8px 24px;
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
  padding: 15px;
}

.course__total__one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #656565;
  font-size: 0.9rem;
}

.course__total__one p:nth-child(2) {
  color: #656565;
}

.course__total__two {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.course__total__two input {
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 8.5px 14px;
  border: 1px solid #c2c2c2;
}

.course__total__two input:focus {
  border: 1px solid rgb(126, 87, 194);
}

.course__total__two button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: sans;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6.8px 15px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(126, 87, 194, 0.5);
  color: rgb(126, 87, 194);
}

.course__total__two button:hover {
  background-color: rgba(126, 87, 194, 0.1);
}

.course__total__three {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}

.course__total__three p:nth-child(1) {
  color: #212121;
}

.course__total__three p:nth-child(2) {
  color: #f15350;
  font-family: sans;
}

.course__total__four {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course__total__four p:nth-child(1) {
  color: #212121;
}

.course__total__four p:nth-child(2) {
  color: #57975b;
  font-family: sans;
}

.course__total__five {
  margin-top: 2rem;
  width: 100%;
}

.course__total__five button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: YekanBakh, IRANSansWeb, Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(126, 87, 194);
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  width: 100%;
  font-weight: 800;
  font-size: 17px;
  border-radius: 11px;
}

.course__total__five button:hover {
  background-color: rgb(82, 36, 163);
}

.cart__shop__container__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.cart__shop__container__empty h4 {
  color: #656565;
  margin-bottom: 2rem;
}

.cart__shop__container__empty a {
  background-color: rgb(126, 87, 194);
  color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 15px 30px;
  border-radius: 15px;
  margin: 0px 4px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 3rem;
}

.cart__shop__container__empty a:hover {
  color: #fff;
}

.swal2-container {
  font-family: kalameh;
}

.swal2-container.swal2-top-end>.swal2-popup, .swal2-container.swal2-top-right>.swal2-popup {
  justify-self: center !important;
}

@media screen and (max-width: 900px) {
  .cart__shop__container {
    flex-direction: column;
  }
  .cart__shop__container__courses {
    width: 100%;
  }
  .course__data {
    width: 100%;
  }
  .cart__shop__container__total {
    width: 100%;
    margin-top: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .course__data__right__data h5 {
    font-size: 0.8rem;
  }
  .course__data__right {
    flex-direction: column;
  }
  .course__data__right__data p {
    font-size: 0.8rem;
  }
}
