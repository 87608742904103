.footer > p {
    font-family: sans;
    text-align: center;
    margin-top: 1.5rem;
}

.footer__container {
    display: flex;
    justify-content: space-between;
    font-family: kalameh;
    margin-top: 6rem;
}

/* One Start */
.one__footer {
    display: flex;
    flex-direction: column !important;
    gap: 1rem;
    width: 50%;
}

.top__one__footer {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.top__one__footer > img {
    width: 10rem;
}

.top__one__footer > div > h4 {
    color: #757575;
    font-size: 1.4rem;
}

.top__one__footer > div > p {
    font-size: .9rem;
    color: #757575;
}
/* One End */

/* Two Start */
.two__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
}

.two__footer > h4 {
    color: #757575;
}

.two__footer > ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.two__footer > ul > li::before {
    content: '';
    background-color: #757575;
    width: 10px;
    height: 10px;
    font-weight: bold;
    display: inline-block;
    margin-left: 10px;
}

.two__footer > ul > li > a {
    color: #656565;
}

.two__footer > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.two__footer > span > svg {
    font-size: 1.5rem;
    color: #7E57C2;
    cursor: pointer;
}

/* Two End */

/* Three Start */

.three__footer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.three__footer > h4 {
    color: #656565;
    font-size: 1.4rem;
}

.three__footer > img {
    width: 6rem;
}

/* Three End */

@media screen and (max-width : 900px) {
    .footer__container {
        flex-wrap: wrap;
    }
    
    .one__footer {
        width: 100%;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width : 600px) {
    .two__footer {
        width: 100%;
        align-items: unset;
        margin-bottom: 3rem;
    }
    
    .two__footer > span {
        justify-content: unset;
        gap: 1rem;
    }

    .top__one__footer {
        flex-direction: column;
    }
}